import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/login.vue'        //登录
import Register from '../views/register.vue'    //注册
import Home from '../views/home.vue'    //内容
import Layout from '../views/layout/layout.vue'
import AppMain from '../views/layout/components/appMain.vue'
import ShareImage from '../views/order-manage/share-image.vue'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
const routes = [
 /* {
    path: '/',
    redirect: 'Home',
    component: Home
  }, */
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: 'register',
    name: 'Register',
    component: Register
  },
  {
    path: '/layout',
    name: 'Layout',
    component: Layout,
    redirect: '/customer-service/order-list',
  },
  {
    path: '/share-image',
    name: 'ShareImage',
    component: ShareImage,
  },
  {
    path: '/customer-service',
    name: 'customerService',
    component: Layout,
    // redirect: '/layout/customer-service/order-list',
    meta: {
      title: '用户管理',
      levelList: [],
      flag: 'use_menu'
    },
    children: [
      {
        path: '/customer-service/auth-list',
        name: 'AuthList',
        meta: {
          title: '账户审核',
          levelList: [],
          flag: 'use_menu_account_list'
        },
        component: () => import('../views/customer-service/auth-list.vue')
      },
      {
        path: '/customer-service/service-list',
        name: 'ServiceList',
        meta: {
          title: '客服列表',
          levelList: [],
          flag:'use_menu_service_list'
        },
        component: () => import('../views/customer-service/service-list.vue')
      }
    ]
  },
  {
    path: '/image-manger',
    name: 'imageManger',
    component: Layout,
    meta: {
      title: '图片管理',
      levelList: [],
      flag: 'picture_menu'
    },
    children: [
      {
        path: '/image-update',
        name: 'imageUpdate',
        meta: {
          title: '图片上传',
          levelList: [],
          flag: 'picture_menu_upload'
        },
        component: () => import('../views/image/update.vue')
      },
      {
        path: '/image-list',
        name: 'imageList',
        meta: {
          title: '图片列表',
          levelList: [],
          flag: 'picture_menu_list'
        },
        component: () => import('../views/image/list.vue')
      },
      {
        path: '/image-common',
        name: 'imageCommon',
        meta: {
          title: '常用图库',
          levelList: [],
          flag: 'picture_menu_storage'
        },
        component: () => import('../views/image/common.vue')
      },
      {
        path: '/menu-manger',
        name: 'menuManger',
        meta: {
          title: '菜单分类',
          levelList: [],
          flag: 'picture_menu_type'
        },
        component: () => import('../views/image/menu-manger.vue')
      },
      {
        path: '/specific-list',
        name: 'specificList',
        hidden: true,
        component: () => import('../views/image/specific-list.vue')
      },
      {
        path: '/select-image',
        name: 'selectImage',
        hidden: true,
        component: () => import('../views/order-manage/select-image.vue')
      },
    ]
  },
  {
    path: '/order-manger',
    name: 'orderManger',
    component: Layout,
    meta: {
      title: '订单管理',
      levelList: [],
      flag: 'order_menu'
    },
    children: [
     {
       path: '/match-image',
       name: 'MatchImage',
       meta: {
         title: '自动配图',
         levelList: [],
         flag: 'order_menu_picture'
       },
       component: () => import('../views/order-manage/match-image.vue')
     },
      {
        path: '/order-manage-list',
        name: 'OrderManageList',
        meta: {
          title: '订单列表',
          levelList: [],
          flag: 'order_menu_list_all',
          flag2: 'order_menu_list_owner'
        },
        component: () => import('../views/order-manage/order-manage-list.vue')
      },
   ]
  },
  {
    path: '/helper',
    name: 'Helper',
    component: Layout,
    meta: {
      title: '客服专区',
      levelList: [],
      flag:'service_menu'
    },
    children: [
      {
        path: '/customer-service/order-list',
        name: 'OrderList',
        meta: {
          title: '订单列表',
          levelList: [],
          flag:'service_menu_order_list_all',
          flag2:'service_menu_order_list_owner'
        },
        component: () => import('../views/customer-service/order-list.vue'),
      },
       {
        path: '/customer-service/customer-list',
        name: 'CustomerList',
        meta: {
          title: '客户列表',
          levelList: [],
          flag:'service_menu_custome'
        },
        component: () => import('../views/customer-service/customer-list.vue')
       },
      {
        path: '/customer-service/customer-detail',
        name: 'CustomerDetail',
        hidden: true,
        component: () => import('../views/customer-service/customer-detail.vue')
      }
    ]
  },
]

const router = new VueRouter({
  routes
})

export default router
