<template>
  <div class="tip" @click="go">
    备案号 鄂ICP备2020020018号-1
  </div>
</template>

<script>
export default {
  name: 'Footer',
  methods:{
    go(){
      // window.open('http://beian.miit.gov.cn/');
      window.open('http://beian.mps.gov.cn/');
    }
  }
}
</script>

<style scoped>
  .tip {
    position: absolute;
    bottom: 5px;
    width: 100%;
    font-size: 12px;
    color: #999aaa;
    height: 16px;
    line-height: 16px;
    text-align: center;
    cursor: pointer;
  }
</style>
