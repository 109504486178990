<template>
  <el-scrollbar wrapClass="scrollbar-wrapper" class="scrollbar-box"  style="height: 100%;">
    <div class="nav-contain" style="height: 100%;">
          <el-menu style="height: 100%;"
            router
            :default-openeds="openeds"
            :default-active="$route.path"
            class="el-menu-vertical-demo"
            background-color="#7b93c3"
            text-color="#000000"
            active-text-color="#ffffff"
          >
            <!-- <AsideBarItem v-for="router in routers"
                           :key="router.path"
                           :router="router"
             >
             </AsideBarItem>-->
            <el-submenu v-for="(router,index) in routers"
                        :index="index + ''"
                        :key="router.name"
                        v-if="router.children">

              <template slot="title">
<!--                <i class="fa fa-bath" aria-hidden="true"></i>-->
                <span v-if="router.meta&&router.meta.title">{{router.meta.title}}</span>
              </template>
              <template v-for="child in router.children">
                <router-link :to="child.path" :key="child.name" v-if="!child.hidden">
                  <el-menu-item :index="child.path" >
                    <i class="fa fa-bath" aria-hidden="true"></i>
                    <span v-if="child.meta&&child.meta.title" slot="title">{{child.meta.title}}</span>
                  </el-menu-item>
                </router-link>
              </template>
              <!--        <span slot="title">{{router.meta.title}}</span>-->
              <!-- 递归有子孙导航组件 -->
              <!-- <AsideBarItem
                 v-for="child in router.children"
                 :key="child.path"
                 :router="child" >
               </AsideBarItem>-->
            </el-submenu>
            <!-- <el-menu-item
               :key="router.path"
               :index="router.path"
               v-else
             >
               {{router.meta.title}}
             </el-menu-item>-->
          </el-menu>


    </div>
  </el-scrollbar>


</template>

<script>
  import AsideBarItem from './asideBarItem'
  import Layout from '../layout'
  export default {
    name: 'mynav',
    components: {
      AsideBarItem
    },
    data(){
      return {
        routers: [],
        openeds: ['0','1','2','3','4']
      }
    },
    created(){
      // console.log('this.$route_+_+_+',this.$route,this.$route.path)
      // console.log('this.$router.options.routes_++_',this.$router.options.routes)
      // var routes = {
      //   children: this.$router.options.routes
      // };
      //
      // var route = this.$route.matched;
      // console.log('this.$route.matched===',this.$route.matched,'route.length-1',route.length-1)
      //
      // for(var i=0; i<route.length-1; i++){
      //   console.log('route===',route)
      //   console.log('e.name route[i].name' , route[i].name)
      //
      //   routes = routes.children.find((e) => (e.name == route[i].name));
      //   console.log('routes+++++++=',routes)
      // }
      // console.log('routes.',routes)
      // console.log('routes.children',routes.children)
      console.log('this.$router.options.routes',this.$router.options.routes)
      const functionCodeList = window.sessionStorage.functionCodeList;
      console.log('functionCodeList',functionCodeList)
      let routes = this.$router.options.routes;
      let accessRoutes = [];
      routes.forEach((router,index) => {
        if(!router.meta){
          accessRoutes.push(router)
        }else{
          if(functionCodeList.includes(router.meta.flag) || functionCodeList.includes(router.meta.flag2)){
            let temp = {}
            Object.assign(temp,{
              path: router.path,
              name: router.name,
              component: router.component,
              meta: router.meta,
            })
            if(router.children && router.children.length > 0){
              Object.assign(temp,{
                children: []
              })
              router.children.forEach((childRouter,index) => {
                if(!childRouter.meta){
                  temp.children.push(childRouter)
                }else{
                  if(functionCodeList.includes(childRouter.meta.flag) > -1 || functionCodeList.includes(childRouter.meta.flag2) > -1) {
                    temp.children.push(childRouter);
                  }
                }
              })
            }
            accessRoutes.push(temp)
          }
        }
      })

      this.routers =  accessRoutes;
      console.log('accessRoutes',accessRoutes)
      // this.routers =  this.$router.options.routes;
    },
    computed: {
      // 获取当前路由的子路由
      /*routers(){
        var routes = {
          children: this.$router.options.routes
        };

        var route = this.$route.matched;
        console.log('this.$route.matched',this.$route.matched)

        for(var i=0; i<route.length-1; i++){
          console.log('route===',route)
          routes = routes.children.find((e) => (e.name == route[i].name));
          console.log('routes+++++++=',routes)
        }
        console.log('routes.children',routes.children)
        return routes.children
      }*/
    }
  }
</script>

<style lang="less" scope>
  .nav-contain{
    text-align: left;
 /*  /deep/ .el-menu-item {
      width: 147px!important;
      min-width: 147px!important;
    }
     /deep/ .el-submenu__title{
      width: 147px!important;
      min-width: 147px!important;
    }
    /deep/ .el-aside.layout-contant {
      width: 147px!important;
    }*/

  }
  .scrollbar-box /deep/ .el-scrollbar__view {
      height: 100%!important;
    }

</style>
