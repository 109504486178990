<template>
  <div class="appMain-container">
    <keep-alive>
      <router-view></router-view>
    </keep-alive>
  </div>
</template>
<script>
  export default {
    data () {
      return {
      }
    }
  }
</script>
<style lang="less" scoped>
  .appMain-container {
    height: 100%;
    display: flex;
  }
</style>
