<template>
  <div class="app-contain">
    <div class="header">
      <div class="user-name">欢迎您:{{name}}</div>
      <div @click="reset" class="date-box" style="padding-right: 10px;cursor:pointer;color:#fff;">
        <i class="el-icon-lock"></i>
        重置密码
      </div>
      <el-button type="danger" @click="layout" size="small" style="margin-right: 30px;">退出</el-button>
    </div>
    <!-- 左导航 -->
    <el-container class="index-container">
      <el-aside class="layout-contant">
        <layout-nav></layout-nav>
      </el-aside>
      <!-- 面包屑 简化学习，暂时屏蔽 -->
      <el-main>
      <!--  <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item v-for="item in levelList" :key="item.path">
            <a :href="item.path" v-if="item.parent">{{item.meta.title}}</a>
            <span v-else>{{item.meta.title}}</span>
          </el-breadcrumb-item>
        </el-breadcrumb> &ndash;&gt;-->

      <!-- 右边显示区域 -->
        <app-main></app-main>
      </el-main>
    </el-container>
    <el-dialog title="重置密码" :close-on-click-modal="false" :visible.sync="dialogTableVisible" center :append-to-body='true' :lock-scroll="false" width="400px">
      <div class="">
        <div class="first-line">
          <div class="date-box">
            <div class="label" style="width: 90px;text-align: right;">原密码</div>
            <el-input
              show-password
              style="width:200px;"
              placeholder="请输入原密码"
              v-model="oldPswd"
              clearable>
            </el-input>
          </div>
        </div>
        <div class="first-line">
          <div class="date-box">
            <div class="label" style="width: 90px;text-align: right;">新密码</div>
            <el-input
              show-password
              style="width:200px;"
              placeholder="请输入新密码"
              v-model="newPswd"
              clearable>
            </el-input>
          </div>
        </div>
        <div class="first-line">
          <div class="date-box">
            <div class="label" style="width: 90px;text-align: right;">密码确认</div>
            <el-input
              style="width:200px;"
              show-password
              placeholder="请再次输入新密码"
              v-model="newPswd2"
              clearable>
            </el-input>
          </div>
        </div>
        <div style="display: flex;justify-content: center;">
          <el-button width="80px" type="primary" size="mid" style="margin-top: 30px;" @click="confirm">确定</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import LayoutNav from './components/nav.vue'
  import AppMain from './components/appMain.vue'
  export default {
    name: 'mynav',
    components: {
      LayoutNav, AppMain
    },
    data(){
      return {
        name: window.sessionStorage.userNameForShow,
        oldPswd: '',
        newPswd: '',
        newPswd2: '',
        dialogTableVisible: false

      }
    },
    created(){
      // console.log('this.$route=====',this.$route)
      // console.log('this.$router=====',this.$router)
    },
    methods: {
      layout(){
        this.$confirm('确定退出吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          window.sessionStorage.manageToken = '';
          this.$message({
            type: 'success',
            message: '退出成功',
            duration: 800
          });
          setTimeout(()=>{
            this.$router.push({ name: "Login" });
          },1000)
        }).catch(() => {

        });
      },
      reset(){
        this.oldPswd = ''  //旺旺名
        this.newPswd = ''  //订单号
        this.newPswd2 = ''
        this.dialogTableVisible = true;
        // this.$router.push({ path: "/customer-service/order-create" });
      },
      confirm(){
        if(!this.newPswd || !this.newPswd2 || !this.oldPswd){
          this.$message.error('请完整输入信息');
          return;
        }
        if(this.newPswd !== this.newPswd2){
          this.$message.error('两次输入的新密码不一致');
          this.newPswd = '';
          this.newPswd2 = '';
          return;
        }
        this.$http.post("/user/resetPassword", {
          password: this.oldPswd,
          newPassword: this.newPswd,
        },{ headers: {
            token: window.sessionStorage.manageToken
          }})
          .then(res => {
            console.log('res====',res)
            console.log('resdata====',res.data)
            //  console.log("输出response.data", res.data);
            if (res.data.code === 'ok') {
              console.log('resdatadata====',res.data.data)
              this.$message({
                message: '重置成功',
                type: 'success',
                duration: 800
              });
              // this.$message.success('注册成功',100)
              window.sessionStorage.password = ''
              setTimeout(()=>{
                this.$router.push({ name: "Login" });
              },1000)
            } else {
              this.$message.error(res.data.data)
            }
          });
      }
    }


  }
</script>
<style lang="less" scope>
  .app-contain {
    overflow-y: hidden;
    flex: 1;
  }
  .header {
    width: 100%;
    height: 40px;
    background-color: #7b93c3;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .index-container {
    height: 90%;
    /deep/ .el-scrollbar__wrap {
      overflow: hidden;
    }
  }
 .layout-contant {
      width: 147px!important;
      height: 100%;
      background-color: #7b93c3;
    }
  .user-name {
    margin-right: 15px;
    font-size: 16px;
    color:#fff;
  }
  .date-box {
    display: flex;
    align-items: center;
    padding-left: 12px;
  }
  .first-line {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 10px;
  }
  .label {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    /*text-align: right;*/
    font-size: 16px;
    color: #000000;
    padding-right: 10px;
  }
</style>

