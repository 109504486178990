<template>
  <div class="container">
    <div class="content">
      <div class="content-bottom">
        <div class="content-box">
          <div class="food-content" v-for="(value,key) in tableData" :key="'key'+key">
            <div class="secondName">
              <span>{{value.secondName}}</span>
            </div>
            <div style="display: flex;flex-wrap: wrap;justify-content: space-between;">
              <div class="food-box" v-for="(item,index) in value.orderInfoLastList" :key="index">
                <div style="width:100%;height: 200px; border: 3px solid #999;box-sizing: border-box;">
                  <img alt="" v-if="item.imgSmallUrl" class="food-image" :src="baseURL + '/images'+item.imgSmallUrl">
                </div>
                <div class="image-size">
                  <div>
                    <span class="image-label el-icon-remove" @click="confirm('free',item)">免图</span>
                    <span class="image-label el-icon-right" @click="changImage(value,item)">换图</span>
                  </div>
                </div>
                <div class="image-desc">
                  <div class="image-info">
                    <div class="info-item">
                      {{item.lastName || '暂无'}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="!(tableData && tableData.length > 0)">暂无数据</div>
        </div>
      </div>
    </div>
    <el-dialog title="在线选图" :close-on-click-modal="false" :visible.sync="dialogTableVisible" center :append-to-body='true' :lock-scroll="false" width="80%">
      <div style="height: 50%;">
        <div class="first-line">
          <div class="date-box" style="padding-left: 12px;">
            <div class="label" style="width: 50px;text-align: right;">名称</div>
            <el-input
              placeholder="请输入名称"
              v-model="lastName"
              clearable>
            </el-input>
          </div>
          <div class="date-box" style="padding-left: 12px;">
            <el-button type="primary" icon="el-icon-search" @click="search">查询</el-button>
          </div>
        </div>
        <div v-if="imgDataList && imgDataList.length > 0" style="text-align: center;padding-bottom: 10px;">请选择下方要替换的图片</div>
        <div v-if="imgDataList && imgDataList.length > 0" class="dialog-image-box" style="display: flex;flex-wrap: wrap;justify-content: space-between;">
          <div @click="confirm('change',item)" class="food-box" v-for="(item,index) in imgDataList" :key="index">
            <div style="width:100%;height: 150px; border: 3px solid #999;box-sizing: border-box;">
              <img style="width: 100%;height: 100%;object-fit: contain;" :src="baseURL + '/images'+item.imgSmallUrl">
            </div>
          </div>
        </div>
        <div style="text-align: center;font-size: 14px;color: #909399;margin-top: 10px;margin-bottom: 10px;" v-else>暂无数据</div>
        <el-pagination
          style="display: flex;justify-content: center"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="pageSize"
          :page-sizes="[5, 10, 20, 40]"
          small
          layout="prev, pager, next"
          :total="total"
        ></el-pagination>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        tableData: [],
        imgDataList: [],
        changeItem:{},
        value: '',
        total: 0,
        pageSize: 4,
        currentPage:1,
        type: '',
        id: '',
        dialogTableVisible:false,  //添加弹框
        queryId: '',  //上个界面
        findPictureData: {},
        lastName:'',
        baseURL: ''
      };
    },
    created () {
      this.baseURL = window.baseURL;
      this.queryId = this.$route.query.orderKey;
      console.log('this.queryid',this.queryId)
      this.findOrderInfo();
    },
    methods: {
      findOrderInfo(){
        this.$http.post("/order/findOrderByKey", {orderKey: this.queryId}, { headers: {
            token: window.sessionStorage.manageToken
          }})
          .then(res => {
            console.log('res====',res)
            console.log('resdata====',res.data)
            if (res.data.code === 'ok') {
              console.log('resdatadata====',res.data.data)
              this.tableData = res.data.data;
            } else {
              this.$message.error(res.data.data)
            }
          });
      },
      search(){
        this.currentPage = 1;
        const data = {
          lastName: this.lastName
        };
        this.findPictureData = data;
        this.findPicture(this.currentPage,this.pageSize);
      },
      handleSizeChange: function (size) {
        this.pageSize = size;
        console.log(this.pageSize);  //每页下拉显示数据
        this.findPicture(this.currentPage,this.pageSize);
      },
      handleCurrentChange: function(currentPage){
        this.currentPage = currentPage;
        console.log(this.currentPage);  //点击第几页
        this.findPicture(this.currentPage,this.pageSize);
      },
      back(){
        this.$router.go(-1);
      },
      changImage(value,item){
        console.log('item===',value,item);
        this.changeItem = item;
        const data = {
          // firstId: item.firstId,
          // secondId: value.secondId,
          // lastId: item.lastId,
          lastName: item.lastName
        };
        this.findPictureData = data;
        this.findPicture(this.currentPage,this.pageSize);
      },
      findPicture(currentPage,pageSize){
        this.$http.post(`/order/findPicture/${currentPage}/${pageSize}`, this.findPictureData , { headers: {
            token: window.sessionStorage.manageToken,
          }})
          .then(res => {
            console.log('res====',res)
            if (res.data.code === 'ok') {
              console.log('resdatadata====',res.data.data)
              this.imgDataList = res.data.data.list;
              this.total = res.data.data.total;
              this.lastName = this.findPictureData.lastName;
              this.dialogTableVisible = true;
            } else {
              this.$message.error(res.data.data)
            }
          });
      },
      confirm(type,item){
        console.log('type===',type)
        let data = {
          id:this.changeItem.id,
          orderKey: this.queryId
        };
        type !== 'free' && (data.imgId = item.imgId);
        console.log('data',data)
        this.$http.post(`/order/choosePictureByKey`, data , { headers: {
            token: window.sessionStorage.manageToken,
          }})
          .then(res => {
            console.log('res====',res)
            if (res.data.code === 'ok') {
              console.log('resdatadata====',res.data.data)
              const text = type === 'free' ? '免图成功' :'换图成功'
              this.$message.success(text)
              this.dialogTableVisible = false;
              this.findOrderInfo();
            } else {
              this.$message.error(res.data.data)
            }
          });
      },
    },
    watch: {
      dialogTableVisible(newVal){
        if(!newVal){
          this.currentPage = 1;
          this.lastName = '';
          this.imgDataList = [];

        }
      },
    },
    filters: {

    },
    computed: {

    }
  };
</script>
<style lang="less" scoped>
  .container {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }
  .content {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-top: 10px;
    /*margin-bottom: 20px;*/
    overflow: auto;
    .content-top {
      display: flex;
      margin-top: 10px;
      justify-content: flex-start;
      flex-direction: column;
    }
    .content-bottom {
      flex: 1;
      display: flex;
      flex-direction: column;
      overflow: auto;
    }
  }
  .first-line {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }
  .label {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    /*text-align: right;*/
    font-size: 16px;
    color: #000000;
    padding-right: 10px;
  }
  .date-box {
    display: flex;
    align-items: center;
    padding-left: 12px;
  }
  .third-line {
    margin-left: 20px;
    margin-top: 10px;
  }
  .content-box {
    display: flex;
    flex-direction: column;
  }
  .food-content {
    /*flex:1;*/
    display: flex;
    flex-direction: column;
    /*flex-wrap: wrap;*/
    /*justify-content: flex-start;*/
    margin: 10px;
    margin-bottom: 0;
    font-size: 12px;
    overflow: auto;
    .secondName {
      background-color: rgb(111,161,212);
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      height: 38px;
      font-size: 16px;
      position: relative;
      .button-box {
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  .food-box {
    display: flex;
    flex-direction: column;
    width: 48%;
    margin: 0 0 10px;
    position: relative;
    /deep/ .el-checkbox {
      position: absolute;
      top: 5px;
      left: 5px;
    }
    /deep/ .el-checkbox__label {
      display: none;
    }
  }
  .food-image {
    width: 100%;
    height: 200px;
    object-fit: contain;//转化不同类型
    /*padding: 3px;*/
    /*background-color: #999;*/
    /*box-sizing: border-box;*/
  }
  .image-size {
    display: flex;
    background-color: #999;
    padding: 3px 0 4px;
    align-items: center;
    justify-content: center;
    div {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 3px;
      .image-label {
        display: flex;
        background-color: #ccc;
        width: 40px;
        padding: 3px 10px;
        border-radius: 20px;
        background-color: rgb(225,229,238);
        &:first-child {
          margin-right: 10px;
        }
      }
      .image-value {
        display: flex;
        flex: 1;
        background-color: #fff;
        padding: 3px;
        border-radius: 0 10px 10px 0;
      }
    }
  }
  .image-desc {
    display: flex;
    flex-direction: column;
    .big-type {
      font-size: 14px;
      text-align: left;
      padding: 3px 0 3px 10px;
      border: 3px solid #ccc;
    }
    .image-info {
      font-size: 14px;
      border: 3px solid #ccc;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #ccc;
      .info-item{
        flex: 1;
        /*display: flex;*/
        background-color: #fff;
        margin-right: 5px;
      }
    }
  }
  /deep/ .cell {
    text-align: center;
  }
  /deep/ .el-tooltip.copy  {
    margin-right: 0!important;
    width: auto!important;
  }
  .dialog-image-box {
    display: flex;
    flex-wrap: wrap;
    height: 330px;
  }
  .dialog-image-item {
    width: 150px;
    height: 150px;
    padding: 5px;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;;//转化不同类型
    }
    .image-radio {
      position: absolute;
      top: 10px;
      left: 10px;
      /deep/ .el-radio__label {
        display: none;
      }
    }
  }
</style>
