<template>
  <div class="asideBarItem-contant">
    <!-- 如果hasOwnProperty监测有children 就循环递归展示 -->
    <el-submenu
      :index="router.path"
      v-if="router.children">
      <span slot="title">{{router.meta.title}}</span>

      <!-- 递归有子孙导航组件 -->
     <!-- <AsideBarItem
        v-for="child in router.children"
        :key="child.path"
        :router="child" >
      </AsideBarItem>-->
    </el-submenu>

    <!-- 无子孙导航 -->
    <el-menu-item
      :key="router.path"
      :index="router.path"
      v-else
    >
      {{router.meta.title}}
    </el-menu-item>
  </div>
</template>
<script>
  // import AsideBarItem from './asideBarItem'
  export default {
    name: 'AsideBarItem',
    components: {
      // AsideBarItem
    },
    created(){

    },
    computed: {
      // 获取当前路由的子路由
      router(){
        var routes = {
          children: this.$router.options.routes
        };

        var route = this.$route.matched;

        for(var i=0; i<route.length-1; i++){
          routes = routes.children.find((e) => (e.name == route[i].name));
        }

        return routes
      }
    }
  }
</script>
