<template>
  <div class="login-demo">
    <div class="login-wrap">
      <div class="login-left" >
        <img src="@/assets/imgs/login-left.png" alt="">
      </div>
      <div class="login-right">
          <!-- 登录框 -->
        <el-form ref="loginForm" :rules="formRules" :model="user" status-icon label-width="90px">
            <div class="title">用 户 注 册</div>
            <!-- 注册框 -->
              <el-form-item prop="name" label="姓名">
                <el-input v-model="user.name" placeholder="请输入姓名" clearable></el-input>
              </el-form-item>
              <el-form-item prop="qq" label="QQ">
                <el-input v-model="user.qq" placeholder="请输入QQ" clearable></el-input>
              </el-form-item>
              <el-form-item prop="wechat" label="微信号">
                <el-input v-model="user.wechat" placeholder="请输入微信号" clearable></el-input>
              </el-form-item>
              <el-form-item prop="phone" label="手机">
                <el-input v-model="user.phone" placeholder="请输入手机号" clearable></el-input>
              </el-form-item>
              <el-form-item prop="password" label="密码">
                <el-input v-model="user.password" show-password placeholder="请输入密码" clearable></el-input>
              </el-form-item>
              <el-form-item prop="password2" label="密码确认">
                <el-input v-model="user.password2" show-password placeholder="请再次输入密码" clearable></el-input>
              </el-form-item>
              <el-form-item style="margin-top: 10px;" class="register-button">
                <el-button type="primary" @click="doRegister('loginForm')">提交注册</el-button>
              </el-form-item>
        </el-form>
      </div>
    </div>
  </div>

</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
export default {
  name: "Login",
  data() {
    return {
      activeName: "login",
      checked: false,
      user: {
        name: "",
        qq: "",
        wechat: "",
        phone: "",
        password: "",
        password2: "",
      },
      // 通过 formRules 属性传入约定的验证规则
      formRules: {
        name: [{ required: true, message: "用户名不能为空", trigger: "blur" },
                  /*  { type: "string", min: 2, message: "账号不能小于2位", trigger: "blur" },
                  { type: "string", max: 20, message: "账号不能大于20位", trigger: "blur" },*/
                  {pattern: /^[_0-9a-zA-Z\u4E00-\u9FA5]+$/, message: '账号只能为中文、数字、字母、下划线', trigger: 'blur'}
        ],
        password: [
          { required: true, message: "密码不能为空", trigger: "blur" },
          /*  { type: "string", min: 6, message: "密码不能小于6位", trigger: "blur" },
            { type: "string", max: 20, message: "密码不能大于20位", trigger: "blur" },*/
          // {pattern: /^[_0-9a-zA-Z]+$/, message: '账号只能为中文、数字、字母、下划线', trigger: 'blur'}
        ],
        password2: [
          { required: true, message: "密码不能为空", trigger: "blur" },
        ],
        qq: [{pattern: /^[0-9]+$/, message: 'qq号只能为数字', trigger: 'blur'}],
        wechat: [{pattern: /^[_0-9a-zA-Z]+$/, message: '微信号只能为数字、字母、下划线', trigger: 'blur'}],
        phone: [
          { required: true, message: "手机号不能为空", trigger: "blur" },
          {pattern: /^[0-9]+$/, message: '手机号只能为数字', trigger: 'blur'}],
        // bug--由于此处登录和注册共用一个el-form，因此只能绑定一个rules,如果邮箱设置为必填，将导致无法登陆。一般情况下，将登录和注册分别放在不同的el-form中，为它们设置不同的rules
      }
    }
  },
  created() {},
  methods: {
    // 注册账号
    doRegister(form) {

      this.$refs[form].validate((valid) => {
        if (valid) {
          console.log(valid);
          console.log('校验通过==')
          if(this.user.password !== this.user.password2){
            this.$message.error('两次输入的密码不一致');
            this.user.password = '';
            this.user.password2 = '';
            return;
          }
          this.$http.post("/user/register", {
            name: this.user.name,
            password: this.user.password,
            qq: this.user.qq,
            phone: this.user.phone,
            wechat: this.user.wechat
          })
            .then(res => {
              console.log('res====',res)
              console.log('resdata====',res.data)
              //  console.log("输出response.data", res.data);
              if (res.data.code === 'ok') {
                console.log('resdatadata====',res.data.data)
                this.$message({
                  message: '注册成功',
                  type: 'success',
                  duration: 800
                });
                // this.$message.success('注册成功',100)
                window.sessionStorage.name = this.user.phone;
                setTimeout(()=>{
                  this.$router.push({ name: "Login" });
                },1000)
              } else {
                this.$message.error(res.data.data)
              }
            });
        } else {
          return false;
        }
      });
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    // 记住密码
    savePassword() {
      if (document.getElementById("savePassword").checked) {
        var username = this.user.username;
        var password = this.user.password;
        window.sessionStorage.username = username;
        window.sessionStorage.password = password;
        localStorage.rmbPassword = true;
      } else {
        localStorage.rmbPassword = false;
      }
    }
  },
  watch: {

  },

}
</script>
<style lang="less" scoped>
  /*.login-demo {
    width: 100%;
    height: 100%;
    background-image: url("~@assets/imgs/login-bg.png");
    background-size: cover;
  }*/

  /* 公共样式 */
  li {
    list-style: none;
    float: left;
  }

  .login-demo {
    /*position: fixed;*/
    width: 100%;
    height: 100%;
    background: url("~@assets/imgs/login-bg.png") no-repeat;
    background-size: cover;
    /*padding-top: 84px;*/
    /*margin: auto;*/
    /*top: 0;*/
    /*left: 0;*/
    /*overflow: hidden;*/
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .login-wrap {
    display: flex;
    /*width: 680px;*/
    /*width: 60%;*/
    width: 986px;
    /*height: 80%;*/
    height: 510px;
    margin: 0 auto;
    background-color: #ffffff;
    box-shadow: 0 4px 14px 0 rgba(133, 169, 231, 0.51);
    /*border-radius: 0.1rem;*/
    border-radius: 5px;
  }
  .login-left {
    /*width: 3.4rem;*/
    /*height: 3.17rem;*/
    display: flex;
    width: 50%;
    /*height: 317px;*/
    img {
      width: 100%;
    }

  }
  .login-right {
    /*background: url("~@assets/imgs/login-bg.png") no-repeat;*/
    /*width: 3.4rem;*/
    /*height: 3.17rem;*/
    display: flex;
    justify-content: center;
    width: 50%;
    /*height: 317px;*/
    /*margin: 175px auto;*/
    /*margin-left: 272px;*/
    /*border-radius: .06rem;*/
    /*line-height: 20px;*/
    /*padding-top: 0;*/
    .title {
      color: #666;
      font-size: 24px;
      margin-top: 30px;
      margin-bottom: 20px
    }
  }

  .el-tabs .el-tabs__item {
    font-size: 18px;
    margin: 5px auto;
  }

  .el-tabs__nav {
    font-size: 18px;
  }

  a {
    text-decoration: none;
    color: #606266;
    font-size: 14px;
    float: right;
  }

  a:hover {
    color: coral;
  }

  .el-checkbox {
    text-indent: 4px;
  }

  .el-form-item__label {
    /*width: 90px;*/
    text-align: center;
    padding: 0 4px 0 0;
  }
  .el-form-item {
    margin-bottom: 20px;
  }
  .el-checkbox__label {
    padding-left: 0;
  }

  .el-button {
    margin-top: 10px;
    /*width: 118%;*/
    /*margin-left: -40px;*/
    /*background-color: #16696a;*/
    /*letter-spacing: 5px;*/
    border: 0;
  }

  .register-button /deep/ .el-form-item__content {
    margin-left: 0!important;
  }
</style>
