<template>
  <div class="login-wrap">
    <el-form ref="loginForm" :model="form" :rules="rules" label-width="80px" class="login-box">
      <h3 class="login-title">选 图 <span>系</span> 统</h3>
      <el-form-item label-width="0" size="medium" prop="username" style="padding-bottom: 8px;">
        <el-input style="width: 295px" type="text" prefix-icon="el-icon-user" placeholder="请输入会员号或手机号" v-model="form.username" clearable/>
      </el-form-item>
      <el-form-item label-width="0" size="medium"  prop="password">
        <el-input style="width: 295px"  type="password"  prefix-icon="el-icon-lock" placeholder="请输入密码" v-model="form.password" clearable/>
      </el-form-item>
      <div class="password-tips">
        <el-checkbox class="save-password" v-model="rmbPsdChecked">记住密码</el-checkbox>
        <div class="register" @click="toRegister">没有账号，点击注册会员!!</div>
      </div>
      <el-form-item label-width="0">
        <el-button style="width:100%;border-radius: 2.5px;" type="primary" v-on:click="onSubmit('loginForm')">登录</el-button>
      </el-form-item>
    </el-form>

    <el-dialog
      title="温馨提示"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose">
      <span>请正确输入账号或密码</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    name: "Login",
    data() {
      return {
        form: {
          username: window.sessionStorage.name,
          password: ''
        },
        rmbPsdChecked: false,  //记住密码
        // 表单验证，需要在 el-form-item 元素中增加 prop 属性
        rules: {
          username: [
            {required: true, message: '账号不可为空', trigger: 'blur'},
          /*  { type: "string", min: 2, message: "账号不能小于2位", trigger: "blur" },
            { type: "string", max: 20, message: "账号不能大于20位", trigger: "blur" },*/
            {pattern: /^[_0-9a-zA-Z\u4E00-\u9FA5]+$/, message: '账号只能为中文、数字、字母、下划线', trigger: 'blur'}
          ],
          password: [
            {required: true, message: '密码不可为空', trigger: 'blur'},
          /*  { type: "string", min: 6, message: "密码不能小于6位", trigger: "blur" },
            { type: "string", max: 20, message: "密码不能大于20位", trigger: "blur" },*/
          ]
        },

        // 对话框显示和隐藏
        dialogVisible: false
      }
    },
  /*  activated(){
      console.log(' this.$router.options.routes,this.rmbPsdChecked',this.$router.options.routes)
      this.rmbPsdChecked = window.sessionStorage.rmbPassword
      if(this.rmbPsdChecked){
        this.form.password =  window.sessionStorage.password;
      }
    },*/
    mounted(){
      console.log(' this.$router.options.routes,this.window.sessionStorage.rmbPassword',window.sessionStorage.rmbPassword)
      this.rmbPsdChecked = window.sessionStorage.rmbPassword ? true : false;
      if(this.rmbPsdChecked){
        this.form.password =  window.sessionStorage.password;
      }
    },
    methods: {
      onSubmit(formName) {
        window.sessionStorage.name = this.form.username;
        if(this.rmbPsdChecked){
          window.sessionStorage.password = this.form.password;
        }
        // 为表单绑定验证功能
        this.$refs[formName].validate((valid) => {
          if (valid) {
            console.log('校验通过==')
            this.$http.post("/user/login", {
              // .post("/api/login/", {
              username: this.form.username,
              password: this.form.password
            })
              .then(res => {
                console.log('res====',res)
                console.log('resdata====',res.data)
                //  console.log("输出response.data", res.data);
                if (res.data.code === 'ok') {
                  console.log('resdatadata====',res.data.data)
                  window.sessionStorage.manageToken = res.data.data.token;
                  window.sessionStorage.userNameForShow = this.form.username;
                  window.sessionStorage.functionCodeList = res.data.data.functionCodeList;
                  this.$router.push({ path: "/layout" });
                } else {
                  this.$message.error(res.data.data)
                }
              });

            //todo  mock
            // this.$router.push({ path: "/layout" });
          } else {
            // this.dialogVisible = true;
            return false;
          }
        });
      },
      //去注册
      toRegister(){
        this.$router.push({ name: "Register" });
      },
      //点击登录校验弹框
      handleClose(){}

    },
    watch: {
      rmbPsdChecked(newVal){
        console.log(111111,newVal)
        if (newVal) {
          // window.sessionStorage.username =  this.form.username;
          window.sessionStorage.password = this.form.password;
          window.sessionStorage.rmbPassword = true;
          // localStorage.rmbPassword = true;
        } else {
          // localStorage.rmbPassword = false;
          window.sessionStorage.password = '';
          window.sessionStorage.rmbPassword = false;
        }
      }
    }
  }
</script>

<style lang="less" scoped>
  .login-wrap {
    /*height: 100%;*/
    display: flex;
    justify-content: center;
    flex: 1;
    padding-top: 90px;
    background: url("~@assets/imgs/login-bg.png") no-repeat;
    background-size: cover;
  }
  .login-box {
    border: 1px solid #DCDFE6;
    /*width: 541px;*/
    /*margin: 180px auto;*/
    padding: 54px 125px 34px 125px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    /*box-shadow: 0 0 25px #909399;*/
    background-color: #fff;
    height: 358.5px;
    box-shadow: 0 3.5px 13.5px 0 rgba(133, 169, 231, 0.51);
    border-radius: 10px;
  }

  .login-title {
    /*text-align: center;
    margin: 0 auto 40px auto;
    color: #303133;*/
    font-family: DFHeiGB-W9;
    font-weight: normal;
    font-stretch: normal;
    /*line-height: 5.76rem;*/
    /*letter-spacing: 1.55rem;*/
    font-size: 26px;
    color: #666;
    padding-bottom: 33px;
    span {
      color: #317fff;
    }
  }
  .password-tips {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-bottom: 58px;
    .save-password {
      display: flex;
      align-items: center;
      font-family: DFHeiGB-W5;
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      color: #666;
      opacity: 0.6;
    }
    .register {
      font-family: DFHeiGB-W5;
      font-size: 10px;
      font-weight: normal;
      font-stretch: normal;
      color: #317fff;
    }
  }

</style>
